import type {
  RpcMethodReturnType,
  RpcMethodName,
  RpcMethods,
  RpcMethodParameters,
} from '@scayle/storefront-core'
import { hash, objectHash } from 'ohash'
import { rpcCall } from '@scayle/storefront-nuxt'

type ValueOf<T> = T[keyof T]
type KeysMatching<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never
}[keyof T] // https://stackoverflow.com/a/54520829/754604

export const useFimRpc = <
  N extends RpcMethodName, // Allow an RPC Method or RPC Method string name
  M extends N extends RpcMethodName ? N : KeysMatching<RpcMethods, N>, // Normalize to the RPC Method name
  P extends RpcMethodParameters<M>, // Extract the parameters of the RPC Method
  TResult extends N extends ValueOf<RpcMethods>
    ? Exclude<Awaited<ReturnType<N>>, Response>
    : Exclude<Awaited<RpcMethodReturnType<M>>, Response>, // Extract the return type
>(
  method: N,
  key: string,
) => {
  const nuxtApp = useNuxtApp()
  const { $currentShop } = nuxtApp
  const fetching = useState(`${key}-fetching`, () => false)
  const data = useState<TResult | null>(`${key}-data`, () => null)
  const wrappedCall = rpcCall(nuxtApp, method, toValue($currentShop))

  const fetch = async (params: Partial<P> = {}) => {
    const key = hash(objectHash(params))
    fetching.value = true
    try {
      if (!nuxtApp._asyncDataPromises[key]) {
        nuxtApp._asyncDataPromises[key] = wrappedCall(params)
      }

      data.value = await nuxtApp._asyncDataPromises[key]
    } finally {
      fetching.value = false
    }
  }

  return {
    fetching,
    data,
    fetch,
  }
}
